import React  from 'react';
import MyNavbar from '../global-components/mynavbar';
import MyFooter from '../global-components/my-footer';
import port1 from '../../images/web/port1.jpg';

import PortfolioS1 from '../section-components/portfolio-S1';
import Gallery from '../section-components/gallery';


const MyPortfolio = () => {
    return (
        <>
<MyNavbar/>

<div
  className="banner-style-one"
  style={{ backgroundImage: "url(assets/img/bg/bg.png)" }}
>
  <div className="container">
    <div className="row align-center">
      <div className="col-lg-8 banner-one-item">
        <p>Perfct Web Services</p>
        <h2>
          Build great <strong>portfolio</strong>
        </h2>
      </div>
      <div className="col-lg-3 offset-lg-1 banner-one-item text-center">
        <div className="curve-text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 150 150"
            version="1.1"
          >
            <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z" />
            <text>
              <textPath href="#textPath">Award Winning Agency</textPath>
            </text>
          </svg>
          <a
            href="#"
            className="popup-youtube"
            style={{ backgroundImage: "url(assets/img/bg/arrow.png)" }} >
         
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<section className="rn-splite-style bg-color-blackest">
  <div className='container-fluid'>

  <div className="split-wrapper">
    <div className="row align-items-center">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12"id='port55'>
      
        <img src={port1} alt='' className='port22' />
    
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12" id='no-gap'>
      <div className="experience-box">
  <div className="inner-content">
  <div
            className="animated fadeInUp"
            style={{ animationDuration: "1s" }}
          >
    <h2>
      <strong>7+</strong> Years of experience
    </h2>
    </div>
    <div
            className="animated fadeInUp"
            style={{ animationDuration: "1s" }}
          >
    <p>
      These cases are perfectly simple and easy to distinguish. In a free hour,
      when our power of choice is untrammelled data structure.
    </p>
    </div>
    <button type="button" class="btn btn-primary">Our Services</button>
    <a className="btn-animation" href="services.html">
     
    </a>
  </div>
</div>
</div>
</div>
      </div>
    </div>
  </section>

<div className="creative-services-area overflow-hidden ">
  <div className="bg-static">
   
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-8">
        <div className="site-heading">
        <div
            className="animated fadeInUp"
            style={{ animationDuration: "1s" }}
          >
          <p className="sub-title">Services We Offer</p></div>
          <div
            className="animated fadeInUp"
            style={{ animationDuration: "1s" }}
          >
          <h2 className="title">
            Turn Information <br /> Into Actionable Insights
          </h2></div>
        </div>
      </div>
    </div>
  </div>
  <PortfolioS1/>
  <section className='gallery'>
  <div className='container'>
    <div className='row'>
<Gallery/>
    </div>
  </div>
</section>
<section className="about-contact">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
        <div className="av-cta">
          <h2>
          Have any Project in mind?
          </h2>
         <p>Contact us now and one of our representative will talk with you Shortly.</p>
         <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contactus" tabindex="0">Contact Us</a></div>
        </div>
      </div>
    </div>
  </div>
</section>



</div>



<MyFooter/>
</>




)
}

export default MyPortfolio

