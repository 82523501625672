import React, { useEffect }  from 'react';


import Aos from 'aos';
import TestimonialV3 from './testimonial-v3';




const MyTestmonial = () => {
  useEffect(() => {

	  Aos.init();
			
  }, []);

    
  
    return (
        <>


<TestimonialV3/>





 






</>




)
}

export default MyTestmonial

