// src/ReadyShutter.js
import React from 'react';
import slide1 from '../../images/web/slide1.webp';
import slide2 from '../../images/web/slide2.webp';
import of from '../../images/web/of.png';
const WebHeader = () => {
 
  return (
    <>
     <section class="header-section2">
                <div class="container">
                <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="container22">
  <h1 className="text1">DIGITAL PR<img src={of} alt='' className='icon-c' />DUCT</h1>
  <h1 className="text2">DESIGN AGENCY</h1>
</div>
<p>We build engaging user experience for early-stage startups by connecting the dots between users’ needs and the client’s business model.</p></div>
   </div>
   <div className="button-row">
 <div class="bnrrbtn">
  <a class="theme-btn-44 btn btn-effect-11 text-uppercase" href="/" tabindex="0">WordPress maintenance</a></div>
<div class="bnrrbtn">
  <a class="theme-btn-45 btn btn-effect-12 text-uppercase" href="/" tabindex="0">Shopify maintenance</a></div>
</div>

</div>   
<section className='circle-r'>
<div className="container">
  <div className='row'>
  <div className='col-xll-4 col-xl-4 col-lg-4 col-md-4 col-4 '>
  <marquee direction="left"id="marquee">

      <img src={slide1} alt='' className='move-left' />
      </marquee>
      </div>
      <div className='col-xll-3 col-xl-3 col-lg-4 col-md-4 col-4 '>
  <div className="circles-wrapper">
    <div className="circle circle-lg">
      <div className="circle circle-md">
        <div className="circle circle-sm" />
      </div>
  
    </div>
  </div>
  </div>

      <div className='col-xll-4 col-xl-4 col-lg-4 col-md-4 col-4 '>
<marquee direction="right" id="marquee2">
		
      <img src={slide2} alt='' className='move-right' />
        
      </marquee>
</div>
</div>
</div>
</section>  
               
</section>
    </>
  );
};

export default WebHeader;
