import React from 'react';
import g1 from '../../images/web/g1.webp';
import g2 from '../../images/web/g2.webp';
import g3 from '../../images/web/g3.webp';
import g4 from '../../images/web/g4.webp';
import g5 from '../../images/web/g5.webp';
import g6 from '../../images/web/g6.webp';
import g7 from '../../images/web/g7.webp';
import g8 from '../../images/web/g8.webp';
import g9 from '../../images/web/g9.webp';
import plus from '../../images/web/plus.webp';
import Pstep from '../section-components/pstep';
import Webport1 from '../section-components/web-port-1';
import TestWeb from '../section-components/test-web';
import TabForm from '../section-components/tab-from';
import CardWeb from '../section-components/card-web';
import WebHeader from '../section-components/web-header';
import MyFooter from '../global-components/my-footer';
import MyNavbar from '../global-components/mynavbar';

const newhome = () => {
 return (
<>
<MyNavbar />
<WebHeader/>
<CardWeb/>
<Pstep/>
<Webport1/>
<TestWeb/>
<section className='tools'>
    <div className='container'>
        <div className='row'>
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className='Personalized-two'>
        <h2>Who We Are</h2>
     </div>
     </div>
           
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12"id='col-gap'>
    <div className="tool-left">
      <div className="tool-left-inner">
        <div>
          <p className="tool-stong">We connect with our clients, our communities, and our company. It makes us different. It makes us better.</p>
         
          <p className="content">
          Perfect Web Services Company has an experienced and fanatical team of designers and developers. We work ingeniously and ardently to face and win every challenge we come across.</p>
          <p className="content">
We don’t only take your order and serve you the required services. Instead, we take time to study your business goals and objectives to build a strategic plan to design your website. With our unique design features, we create website that expresses your business values and increase the engagement of the audience that visits your site.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-12 col-12"id='gapleft'>
    <div className="row" id="fully">
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6" >
        <div className="stoll">
          <div className='border1'></div>
        <img src={g2} alt='' className='localseo' />
        <img src={plus} alt='' className='localicon' />
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6" >
        <div className="stoll">
        <img src={g3} alt='' className='localseo' />
          <img src={plus} alt='' className='localicon' />
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6"id='border-none b555' >
        <div className="stoll">
        <img src={g2} alt='' className='localseo' />
        </div>
      </div>
       </div>
      <div className="row" id="fully">
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
        <div className="stoll">
        <img src={g4} alt='' className='localseo' />
        </div>
        <img src={plus} alt='' className='localicon2' />
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6">
        <div className="stoll">
        <img src={g5} alt='' className='localseo' />
      <img src={plus} alt='' className='localicon' />
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6"id='border-none'>
        <div className="stoll">
        <img src={g6} alt='' className='localseo' />
        </div>
    </div>

      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6"id='b2'>
        <div className="stolll">
        <img src={g7} alt='' className='localseo' />
      
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6" id='b2'>
        <div className="stolll">
        <img src={g8} alt='' className='localseo' />
     
        </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-6"id='border-none2'>
        <div className="stolll">
        <img src={g9} alt='' className='localseo' />
        </div>
      </div>
     
    </div>
  </div>
        </div>
        </div>
    
</section>
<TabForm/>
<MyFooter/>
</>
)
}

export default newhome




