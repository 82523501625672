import React, {useEffect } from 'react';
import SS1 from '../../images/web/SS1.png';
import SS2 from '../../images/web/SS2.png';
import SS3 from '../../images/web/SS3.png';
import SS4 from '../../images/web/SS4.png';



	const PortfolioS1 = () => {
		useEffect(() => {
		
	
			
		  }, []);
		return (
			<section className="contain">
				<div className='container'>
				<div className='row' id='business'>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={SS1} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
	<p className='strong'><a href="/">Digital Marketing</a></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisic do eiusmod tempor incididunt ut labore et</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={SS2} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
	<p className='strong'><a href="/">Web Designing</a></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisic do eiusmod tempor incididunt ut labore et</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={SS3} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
	<p className='strong'><a href="/">Product Branding</a></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisic do eiusmod tempor incididunt ut labore et</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={SS4} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <p className='strong'><a href="/">Creative Design</a></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisic do eiusmod tempor incididunt ut labore et</p>
    </div>
    </div>
    </div>
    </div>
    
      </div>
				</div>
				<div className="portfolio-style-one-area default-padding bg-gray">
  <div className="container">
    <div className="heading-left">
      <div className="row">
        <div className="col-lg-6">
          <div className="content-left">
            <p className="sub-title">Popular Projects</p>
            <h2 className="title">Featured Works</h2>
          </div>
        </div>
        <div className="col-lg-5 offset-lg-1">
          <p>
            Continue indulged speaking the was out horrible for domestic
            position. Seeing rather her you not esteem men settle genius excuse.
            Deal say over you age from. Comparison new ham melancholy
            themselves.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

 

</section>



		
		)
}

export default PortfolioS1