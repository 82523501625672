import React, {  useRef,useEffect } from 'react';
import testimonial from '../../images/web/testimonial.webp';
import test2 from '../../images/web/test2.webp';
import jQuery from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.js';


const TestWeb = () => {
  useEffect(() => {
    
    jQuery(document).ready(function ($) {
      var $slickElement = $(".slideshow");
    
      $slickElement.slick({
        autoplay: true,
        dots: false
      });
    });
    
  }, []);
    return (
<>

   
   <section className="testimonial-section" >
  <div className='container'>
  <div className='row'>
   <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
    
   <div className='mutlibox1'>
     <img src={test2} alt='' className='test21' />
     <h5 className='testtext'>Award Winner Developer- PHP | Laravel | WordPress | Webflow | Design</h5>
     <div class="online-details"><div class="couse-count">
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>96% Job Success
        </div>
        <div class="couse-count">
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>Top Rated Plus
        </div>
        <div class="couse-count">
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>FullStack Developer
        </div>
        <div class="couse-count">
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-check-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>Award Winner
        </div>
        </div>
        </div>
     </div>
    
    
     <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <div className='mutlibox'>
        <div className="sd_master_wrapper">
    <div className="sdtestBg2" />
    <div className="sdtestBg3" />
    <div className="slideshow">
      <div className="content">
        {" "}
        {/* slide 1 */}
        <div className="thumbnail2">
        <img src={testimonial} alt='' className='test21' />
        </div>
        <div className="btnNtxt">
          <div className="sdAllContent">
            <div className="sd_scroll">
            <p className="SdClientName">Vice President</p>
            <p className="SdClientDesc">CPF India</p>
              <p className="sdCustomSliderHeadig">
              "Fantastic, high quality, an attention to detail, friendly.. I could go on, overall a pleasure to work with and a great result."
              </p>
            </div>
           
          </div>
        </div>
      </div>
      <div className="content">
        {" "}
        {/* slide 2 */}
        <div className="thumbnail2">
        <img src={testimonial} alt='' className='test21' />
        </div>
        <div className="btnNtxt">
          <div className="sdAllContent">
          <div className="sd_scroll">
          <p className="SdClientName">Allison Rhiel Madsen</p>
            <p className="SdClientDesc">Project Manager</p>
              <p className="sdCustomSliderHeadig">
              "Fantastic, high quality, an attention to detail, friendly.. I could go on, overall a pleasure to work with and a great result."
              </p>
            </div>
           
          </div>
        </div>
      </div>
      <div className="content">
        {" "}
        {/* slide 3 */}
        <div className="thumbnail2">
        <img src={testimonial} alt='' className='test21' />
        </div>
        <div className="btnNtxt">
          <div className="sdAllContent">
          <div className="sd_scroll">
            <p className="SdClientName">Allison Rhiel Madsen</p>
            <p className="SdClientDesc">Project Manager</p>
              <p className="sdCustomSliderHeadig">
              "Fantastic, high quality, an attention to detail, friendly.. I could go on, overall a pleasure to work with and a great result."
              </p>
            </div>
           </div>
        </div>
      </div>
      <div className="content">
        {" "}
        {/* slide 4 */}
        <div className="thumbnail2">
        <img src={testimonial} alt='' className='test21' />
        </div>
        <div className="btnNtxt">
          <div className="sdAllContent">
          <div className="sd_scroll">
            <p className="SdClientName"> Allison Rhiel Madsen</p>
            <p className="SdClientDesc">Project Manager</p>
              <p className="sdCustomSliderHeadig">
              "Fantastic, high quality, an attention to detail, friendly.. I could go on, overall a pleasure to work with and a great result."
              </p>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="spacer" />
    
     <h5 className='test'>Trusted by 100+ top companies across world wild.</h5>
     </div>
   
    </div>
   
  </div>
 </div>
</section>
<section className='slide testimonial'>
<>
  
</>


</section>
 </>
)
}

export default TestWeb

