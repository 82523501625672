import React, { useEffect } from 'react';
import AOS from 'aos';
import $ from 'jquery';
import 'aos/dist/aos.css'; // Import AOS CSS file
const CardWeb = () => {
    useEffect(() => {
        // Initialize AOS when the component mounts
        $(function() {
          AOS.init({
            duration: 1200
          });
        
          $('.js-load-more').on('click', function() {
            var $content = $(this).next('.js-more-content');
            
            $content.animate({
              height: 750,
            }, 500);
          });
        
          onElementHeightChange(document.body, function(){
            AOS.refresh();
          });
        });
        
        function onElementHeightChange(elm, callback) {
            var lastHeight = elm.clientHeight
            var newHeight;
            
            (function run() {
                newHeight = elm.clientHeight;      
                if (lastHeight !== newHeight) callback();
                lastHeight = newHeight;
        
                if (elm.onElementHeightChangeTimer) {
                  clearTimeout(elm.onElementHeightChangeTimer); 
                }
        
                elm.onElementHeightChangeTimer = setTimeout(run, 200);
            })();
          }
        
      }, []);
   
   return (
    <>
                 <section class="card-services">
                <div class="container">
                <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h5
  className="o0 supa wwd"
  style={{
    translate: "none",
    rotate: "none",
    scale: "none",
    
    
    opacity: "0.9931"
  }}
  
  data-aos="fade-up">
  what we do
</h5>
</div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                <div className="service-card design"  style={{ marginTop: 0 }}>
  <div className="name" style={{ opacity: 1 }}>
    <div className='arrowcard'></div> <h3>Design</h3>
  </div>
  <div className="container col g50 fillw card_cont" style={{ opacity: 1 }}>
    {/*[*/}
    <div className="container col g30 fillw">
      <h4>01. Design</h4>
      <hr className="heavy" />
      <p>
        The structure and the prototype are the base from which any project in
        our studio is built
      </p>
    </div>
    <div className="container col g30 fillw">
      <h4>02. UI/UX</h4>
      <hr className="heavy" />
      <p>
        It continues the theme of the project concept and create its final
        appearance, interface and memorable style
      </p>
    </div>
    {/*]*/}
  </div>
</div>

                       
</div> 
<div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
            <div className="service-card frontend" data-aos="fade-dwon">
  <div className="name" style={{ opacity: "0.9087" }}>
  <div className='arrowcard2'></div><h3> Frontend</h3>
  </div>
  <div
    className="container col g50 fillw card_cont"
    style={{ opacity: "0.8696" }}
  >
    {/*[*/}
    <div className="container col g30 fillw">
      <h4>01. Layout</h4>
      <hr className="heavy" />
      <p>
        Creating static pages, thought out in the project and visualized on the
        design
      </p>
    </div>
    <div className="container col g30 fillw">
      <h4>02. Frontend</h4>
      <hr className="heavy" />
      <p>
        Connecting the necessary functionality to interact with the technical
        part of the project
      </p>
    </div>
    {/*]*/}
  </div>
</div>


                       
</div> 
<div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
<div className="service-card backend" style={{ marginTop: 0 }}>
  <div className="name" style={{ opacity: 1 }}>
  <div className='arrowcard3'></div><h3> Backend </h3>
  </div>
  <div className="container col g50 fillw card_cont" style={{ opacity: 1 }}>
    {/*[*/}
    <div className="container col g30 fillw">
      <h4>01. Methods and functions</h4>
      <hr className="heavy" />
      <p>
        Development of mechanics of receiving and transmitting various types of
        data, creation of calculations and data storage
      </p>
    </div>
    <div className="container col g30 fillw">
      <h4>02. API and infrastructure</h4>
      <hr className="heavy" />
      <p>
        Functionality that receives and transmits data from scripts and from the
        database when interacting with them
      </p>
    </div>
    {/*]*/}
  </div>
</div></div> 

</div>                  
   </div>                
               
</section>
       
         
                

        </>          
           
     )
    
}

export default CardWeb;
