import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MyCopyRight extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
			<div className="ltn__copyright-area ltn__copyright-2 section22">
			<div className="container ltn__border-top-2">
				<div className="row">
				<div className="col-md-6 col-12">
					<div className="ltn__copyright-design clearfix">
					<p>2023 Perfect Web Services. All right reserved <span className="current-year" /></p>
					</div>
				</div>
				<div className="col-md-6 col-12 align-self-center">
					<div className="ltn__copyright-menu text-end">
					<ul className="go-top">
             
						<li><Link to="/about">Privacy Policy</Link></li>
						<li><Link to="/about">Terms & Conditions</Link></li>
					
					</ul>
					</div>
				</div>
				</div>
			</div>
			</div>
        )
    }
}


export default MyCopyRight