import React, { useEffect }  from 'react';
import MyNavbar from '../global-components/mynavbar';
import MyFooter from '../global-components/my-footer';
import service_1 from '../../images/web/service_1.jpeg';
import service_2 from '../../images/web/service_2.jpeg';
import service_3 from '../../images/web/service_3.jpeg';
import service_4 from '../../images/web/service_4.jpeg';
import team from '../../images/web/team.gif';
import designing from '../../images/web/designing.gif';
import coding7 from '../../images/web/coding7.gif';
import analyze3 from '../../images/web/analyze3.png';
import startup from '../../images/web/startup.gif';
import sketch from '../../images/web/sketch.gif';



import Aos from 'aos';
import MyTestmonial from '../section-components/my-testmonial';



const OurProcess = () => {
  useEffect(() => {

	  Aos.init();
			
  }, []);

    
  
    return (
        <>
<MyNavbar/>

<div
  className="banner-style-two"
  style={{ backgroundImage: "url(assets/img/bg/bg.png)" }}
>
  <div className="container">
    <div className="row align-items-center">
     <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <div className="seocontentbg1"data-aos="zoom-in" data-aos-duration="3000">
          <h1> OUR PROCESS</h1>
          <p className="bnrtext">
            With 10+ years of working as a preferred Website Design and <br />
            Development company for brands.
          </p>
          <div className="bnrbtn">
            <a
              className="theme-btn-1 btn btn-effect-1 text-uppercase"
              href="tel:+91 9876769096"
              tabIndex={0}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  


      <section className='ppc-sec1'>
<div className="container">
  <div className="row align-items: center
">
    {/* card1 */}
    <div className="card card-circle" data-aos="fade-up"
     data-aos-duration="3000">
           <div className="circle-card-body">
        <h5 className="card-title">Not getting <br />enough leads?</h5>
        <p className="card-text">
        Your brand has great & you’ve many other responsibilities to look after. 
        </p>
             </div>
    </div>
    {/* card2 */}
    <div className="card card-circle" data-aos="fade-up"
     data-aos-duration="3000">
            <div className="circle-card-body">
        <h5 className="card-title2">Worried about how to beat the competitors?</h5>
        <p className="card-text2">
        Your brand has great & you’ve many other responsibilities to look after. 
        </p>
           </div>
    </div>
    {/* card3 */}
    <div className="card card-circle " data-aos="fade-up"
     data-aos-duration="3000">
           <div className="circle-card-body">
        <h5 className="card-title">Don’t have time to analyze?</h5>
        <p className="card-text">
        Your brand has great you’ve many other responsibilities to look after. 
        </p>
           </div>
    </div>
  </div>
</div>
</section>
    </div>
  

  <div id="service">
  <div className="container">
    <div className="row">
      <div className="col-xl-4">
        <div className="cs-section_heading cs-style1"data-aos="fade-right" data-aos-duration="3000">
          <p className="cs-section_subtitle">What Can We Do</p>
          <h2 className="cs-section_title">Services we can help you with</h2>
          <div className="cs-height_45 cs-height_lg_20" />
          <a className="cs-text_btn" href="/">
            <span>See All Services</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              className="iconify iconify--bi"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </a>
        </div>
        <div className="cs-height_90 cs-height_lg_45" />
      </div>
      <div className="col-xl-8">
        <div className="row">
          <div className="col-lg-3 col-sm-6 cs-hidden_mobile"id='hidemb' />
          <div className="col-lg-3 col-sm-6"data-aos="fade-up"
     data-aos-duration="3000">
            <a className="cs-card cs-style1" href="/service/service-details">
            <img src={service_1} alt='' className='Service' />
          
              <div className="cs-card_overlay" />
              <div className="cs-card_info">
                <span className=" cs-hover_layer3 cs-accent_bg" />
                <p className="cs-card_title">UI/UX design</p>
              </div>
            </a>
            <div className="cs-height_0 cs-height_lg_30" />
          </div>
          <div className="col-lg-3 col-sm-6 cs-hidden_mobile"id='hidemb' />
          <div className="col-lg-3 col-sm-6"data-aos="fade-up"
     data-aos-duration="3000">
            <a className="cs-card cs-style1" href="/service/service-details">
            <img src={service_2} alt='' className='Service' />
              <div className="cs-card_overlay" />
              <div className="cs-card_info">
                <span className=" cs-hover_layer3 cs-accent_bg" />
                <p className="cs-card_title">React.js Development</p>
              </div>
            </a>
            <div className="cs-height_0 cs-height_lg_30" />
          </div>
          <div className="col-lg-3 col-sm-6"data-aos="fade-up"
     data-aos-duration="3000">
            <a className="cs-card cs-style1" href="/service/service-details">
            <img src={service_3} alt='' className='Service' />
              <div className="cs-card_overlay" />
              <div className="cs-card_info">
                <span className=" cs-hover_layer3 cs-accent_bg" />
                <p className="cs-card_title">Digital Marketing</p>
              </div>
            </a>
            <div className="cs-height_0 cs-height_lg_30" />
          </div>
          <div className="col-lg-3 col-sm-6 cs-hidden_mobile" id='hidemb'/>
          <div className="col-lg-3 col-sm-6"data-aos="fade-up"
     data-aos-duration="3000">
            <a className="cs-card cs-style1" href="/service/service-details">
            <img src={service_4} alt='' className='Service' />
              <div className="cs-card_overlay" />
              <div className="cs-card_info">
                <span className=" cs-hover_layer3 cs-accent_bg" />
                <p className="cs-card_title">Technology</p>
              </div>
            </a>
            <div className="cs-height_0 cs-height_lg_30" />
          </div>
          <div className="col-lg-3 col-sm-6 cs-hidden_mobile" />
        </div>
      </div>
    </div>
  </div>
</div>

 


<div className="timeline">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
      <h2>Our Process</h2>

        <div className="timeline-container">
          <div className="timeline-end">
            <p>Now</p>
          </div>
          <div className="timeline-continue">
            <div className="row timeline-right">
              <div className="col-md-6">
                <p className="timeline-date">1</p>
              </div>
              <div className="col-md-6">
                <div className="timeline-box">
                  <div className="timeline-icon">
                  <img src={team} alt='' className='Service' />
                   
                  </div>
                  <div className="timeline-text">
                    <p className='strong-text'>Project Discussion</p>
                    <p>First we discuss the project with you</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row timeline-left">
              <div className="col-md-6 d-md-none d-block">
                <p className="timeline-date"></p>
              </div>
              <div className="col-md-6">
                <div className="timeline-box">
                  <div className="timeline-icon d-md-none d-block">
                  <img src={sketch} alt='' className='Service' />
                  </div>
                  <div className="timeline-text2">
                  <p className='strong-text'>Project Planning</p>
                    <p>The purpose of this stage is to understand the vision and mission of your business.</p>
                  </div>
                  <div className="timeline-icon d-md-block d-none">
                  <img src={sketch} alt='' className='Service' />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <p className="timeline-date">2</p>
              </div>
            </div>
       
            <div className="row timeline-right">
              <div className="col-md-6">
                <p className="timeline-date">3</p>
              </div>
              <div className="col-md-6">
                <div className="timeline-box">
                  <div className="timeline-icon">
                  <img src={designing} alt='' className='Service' />
                  </div>
                  <div className="timeline-text">
                  <p className='strong-text'>Designing</p>
                    <p>The safety of your Intellectual Property is of utmost importance to us.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row timeline-left">
              <div className="col-md-6 d-md-none d-block">
                <p className="timeline-date"></p>
              </div>
              <div className="col-md-6">
                <div className="timeline-box">
                  <div className="timeline-icon d-md-none d-block">
                  <img src={coding7} alt='' className='Service' />
                  </div>
                  <div className="timeline-text2">
                  <p className='strong-text'>Coding</p>
                    <p>The purpose of this stage is to understand the vision and mission of your business.</p>
                  </div>
                  <div className="timeline-icon d-md-block d-none">
                  <img src={coding7} alt='' className='Service' />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <p className="timeline-date">4</p>
              </div>
            </div>
            <div className="row timeline-right">
              <div className="col-md-6">
                <p className="timeline-date">5</p>
              </div>
              <div className="col-md-6">
                <div className="timeline-box">
                  <div className="timeline-icon">
                  <img src={analyze3} alt='' className='Service' />
                  </div>
                  <div className="timeline-text">
                  <p className='strong-text'>Testing</p>
                    <p>The safety of your Intellectual Property is of utmost importance to us.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row timeline-left">
              <div className="col-md-6 d-md-none d-block">
                <p className="timeline-date"></p>
              </div>
              <div className="col-md-6">
                <div className="timeline-box">
                  <div className="timeline-icon d-md-none d-block">
                  <img src={startup} alt='' className='Service' />
                  </div>
                  <div className="timeline-text2">
                  <p className='strong-text'>Launch</p>
                    <p>The purpose of this stage is to understand the vision and mission of your business.</p>
                  </div>
                  <div className="timeline-icon d-md-block d-none">
                  <img src={startup} alt='' className='Service' />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-md-block d-none">
                <p className="timeline-date">6</p>
              </div>
            </div>
          </div>
          <div className="timeline-start">
            <p>Launch</p>
          </div>
     
        </div>
      </div>
    </div>
  </div>
</div>

<section className="section-padding">
  <div className="container">
    <div className="row">
      <div className="col-lg-10">
        <div className="text">
        <div className="animated fadeInUp" style={{ animationDuration: "1s" }}>

          <span className="sub-title mb-15 opacity-8">- Who We Are?</span>
          <div className="text-reval">
            <h2 className="fz-50 text-u mb-30">
              <span className="text">We are a creative studio with focus</span>
              <span className="text">on set design, illustration and</span>
              <span className="text"> motion design.</span>
            </h2>
          </div>
          </div>
          <p className='let-text'>
            We create brand identities, digital experiences, and print materials
            that communicate clearly achieve marketing goals, and look
            fantastic. Urban design draws together the many strands of
            place-making, environmental stewardship.
          </p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-10 offset-lg-1">
        <div className="row mt-80">
          <div className="col-md-4 sm-mb30"data-aos="zoom-in" data-aos-duration="3000">
            <ul className="list rest">
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Branding Strategy
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Digital Experiences
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Social Media
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Advertising
              </li>
            </ul>
          </div>
          <div className="col-md-4 sm-mb30"data-aos="zoom-in" data-aos-duration="3000">
            <ul className="list rest">
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Branding Strategy
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Digital Experiences
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Social Media
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Advertising
              </li>
            </ul>
          </div>
          <div className="col-md-4"data-aos="zoom-in" data-aos-duration="3000">
            <ul className="list rest">
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Branding Strategy
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Digital Experiences
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Social Media
              </li>
              <li className="fz-18 mb-15">
                <span className="mr-10">+</span> Advertising
              </li>
            </ul>
          </div>
        </div>
        <div className="row numbers "data-aos="zoom-in" data-aos-duration="3000">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <h3 className="stroke f-bold num pb-30 mb-30 bord-thin-bottom">
                48
              </h3>
              <p className="fz-16">Projects Completed</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item md-mb50">
              <h3 className="stroke f-bold num pb-30 mb-30 bord-thin-bottom">
                64k
              </h3>
              <p className="fz-16">Clients Around the World</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="item">
              <h3 className="stroke f-bold num pb-30 mb-30 bord-thin-bottom">
                30
              </h3>
              <p className="fz-16">Total Awards</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<MyTestmonial/>
<section className="about-contact">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
        <div className="av-cta">
          <h2>
          Have any Project in mind?
          </h2>
         <p>Contact us now and one of our representative will talk with you Shortly.</p>
         <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contactus" tabindex="0">Contact Us</a></div>
        </div>
      </div>
    </div>
  </div>
</section>





<MyFooter/>
</>




)
}

export default OurProcess

