import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import MyCopyRight from './mycopyright';

class MyFooter extends Component {

    componentDidMount() {

    	const $ = window.$;
    	
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){ 
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
    }

    render() {

     
        return (
				<footer className="ltn__footer-area1  ">
				  <div className="footer-top-area1  sectionf">
				    <div className="container">
				      <div className="row">
				   
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <p className="footer-title">Office</p>
				            <div className="footer-menu go-top">
                            <p>E-237AA, Phase 8B, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar, Punjab 160055</p>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <p className="footer-title">New Business</p>
				            <div className="footer-menu go-top">
                           <p> +91-7508738945<br></br>
                            +91-7018158742</p>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-6 col-12">
				          <div className="footer-widget footer-menu-widget clearfix">
				            <p className="footer-title">Mail Us</p>
				            <div className="footer-menu go-top">
				            <p>contact@perfectwebservices.com</p>
				            </div>
				          </div>
				        </div>
				        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                        <div className="footer-widget footer-menu-widget clearfix">
				            <p className="footer-title">Follow US</p>
				            <ul>
				                <li><Link to="/">Twitter</Link></li>
				                <li><Link to="/">Linkedin</Link></li>
				                <li><Link to="/">Instagram</Link></li>
				                <li><Link to="/">Facebook</Link></li>
				                </ul>
				          
				           
				         
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				  <MyCopyRight />
				</footer>
        )
    }
}


export default MyFooter