import React, { useEffect } from 'react';
import $ from 'jquery';


const AboutF1 = () => {

    useEffect(() => {
        let i=2;


        $(document).ready(function(){
        var radius = 200;
        var fields = $('.itemDot');
        var container = $('.dotCircle');
        var width = container.width();
        radius = width/2.5;
        
        var height = container.height();
        var angle = 0, step = (2*Math.PI) / fields.length;
        fields.each(function() {
        var x = Math.round(width/2 + radius * Math.cos(angle) - $(this).width()/2);
        var y = Math.round(height/2 + radius * Math.sin(angle) - $(this).height()/2);
        if(window.console) {
        console.log($(this).text(), x, y);
        }
        
        $(this).css({
        left: x + 'px',
        top: y + 'px'
        });
        angle += step;
        });
        
        
        $('.itemDot').click(function(){
        
        var dataTab= $(this).data("tab");
        $('.itemDot').removeClass('active');
        $(this).addClass('active');
        $('.CirItem').removeClass('active');
        $( '.CirItem'+ dataTab).addClass('active');
        i=dataTab;
        
        $('.dotCircle').css({
        "transform":"rotate("+(360-(i-1)*36)+"deg)",
        "transition":"2s"
        });
        $('.itemDot').css({
        "transform":"rotate("+((i-1)*36)+"deg)",
        "transition":"1s"
        });
        
        
        });
        
        setInterval(function(){
        var dataTab= $('.itemDot.active').data("tab");
        if(dataTab>6||i>6){
        dataTab=1;
        i=1;
        }
        $('.itemDot').removeClass('active');
        $('[data-tab="'+i+'"]').addClass('active');
        $('.CirItem').removeClass('active');
        $( '.CirItem'+i).addClass('active');
        i++;
        
        
        $('.dotCircle').css({
        "transform":"rotate("+(360-(i-2)*36)+"deg)",
        "transition":"2s"
        });
        $('.itemDot').css({
        "transform":"rotate("+((i-2)*36)+"deg)",
        "transition":"1s"
        });
        
        }, 5000);
        
        });
            
          }, []);
 
    return (
<>

 <section className='about-four'>
 <section className="iq-features">
  <div className="container">
    <div className="row align-items-center">
    <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12'id='ab2'>
        <h2>Our Values
</h2>
        <p>
        Our mission is to establish, prepare and provide user friendly, lucrative, flexible and comprehensive solutions to the present and future clients and to maintain a healthy relationship with our clients at the same time.Also to provide the latest technologies services that Improves every client’s Business.Our prime mission is to meet customer requirements as well as our commitments by offering best Web Design and Development services & solutions in the ever-changing world of technology.
   </p>
   </div>
   <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12'id='ab2'>
      <div className="col-lg-3 col-md-12" />
      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12">
        <div className="holderCircle">
          <div className="round" />
          <div className="dotCircle">
            <span className="itemDot active itemDot1" data-tab={1}>
              <i className="fa fa-clock-o" />
              <span className="forActive" />
            </span>
            <span className="itemDot itemDot2" data-tab={2}>
              <i className="fa fa-comments" />
              <span className="forActive" />
            </span>
            <span className="itemDot itemDot3" data-tab={3}>
              <i className="fa fa-user" />
              <span className="forActive" />
            </span>
            <span className="itemDot itemDot4" data-tab={4}>
              <i className="fa fa-tags" />
              <span className="forActive" />
            </span>
            <span className="itemDot itemDot5" data-tab={5}>
              <i className="fa fa-upload" />
              <span className="forActive" />
            </span>
            <span className="itemDot itemDot6" data-tab={6}>
              <i className="fa fa-briefcase" />
              <span className="forActive" />
            </span>
          </div>
          <div className="contentCircle">
            <div className="CirItem title-box active CirItem1">
              <h2 className="title">
                <h3>	Be Proactive</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-clock-o" />
            </div>
            <div className="CirItem title-box CirItem2">
              <h2 className="title">
                <h3>	Straight Talk</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-comments" />
            </div>
            <div className="CirItem title-box CirItem3">
              <h2 className="title">
                <h3>	

Spread Smiles
</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-user" />
            </div>
            <div className="CirItem title-box CirItem4">
              <h2 className="title">
                <h3>Collaborate</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-tags" />
            </div>
            <div className="CirItem title-box CirItem5">
              <h2 className="title">
                <h3>Be Data Driven</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-upload" />
            </div>
            <div className="CirItem title-box CirItem6">
              <h2 className="title">
                <h3>	When in Doubt, Prototype</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-briefcase" />
            </div>
            <div className="CirItem title-box CirItem7">
              <h2 className="title">
                <h3>Keep it Simple</h3>
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <i className="fa fa-briefcase" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-12" />
    </div>
  </div>
  </div>
</section>

    

</section>
<section className='team'>
    <div className='container'>
        <div className='row'>
        <div class="Personalized-two"><h2>Our Team</h2></div>
        <ul className="list-members">
  <li className="member">
    <div className="member-image">
    <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/4.png" />
    </div>
    <div className="member-info">
      <h3>Paras Latawa</h3>
      <p>Founder</p>
    
    </div>
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/2.png" />
    </div>
    <div className="member-info">
      <h3>Hardeep Kaur</h3>
      <p>Senior Developer</p>
     
    </div>
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/3.png" />
    </div>
    <div className="member-info">
      <h3>Paramdeep</h3>
      <p>Senior Designer</p>
 
    </div>
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/4.png" />
    </div>
    <div className="member-info">
      <h3>Priyesh Dhiman</h3>
      <p>Web Developer</p>
     
    </div>
    
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/1.png" />
    </div>
    <div className="member-info">
      <h3>Pragati Singh</h3>
      <p>Senior Seo Expert</p>
      
    </div>
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/2.png" />
    </div>
    <div className="member-info">
      <h3>Navneet Soni</h3>
      <p>Senior BDE</p>
      
    </div>
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/3.png" />
    </div>
    <div className="member-info">
      <h3>Gurpreet</h3>
      <p>Web Designer</p>
  
    </div>
  </li>
  <li className="member">
    <div className="member-image">
      <img src="https://fadzrinmadu.github.io/hosted-assets/team-section-design-using-html-and-css/4.png" />
    </div>
    <div className="member-info">
      <h3>Sandeep</h3>
      <p>HR Executive</p>
     
    </div>
    
  </li>
</ul>

        </div>
    </div>
</section>
<section className="about-contact">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
        <div className="av-cta">
          <h2>
          Have any Project in mind?
          </h2>
         <p>Contact us now and one of our representative will talk with you Shortly.</p>
         <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contactus" tabindex="0">Contact Us</a></div>
        </div>
      </div>
    </div>
  </div>
</section>






</>
)
}

export default AboutF1

