import React, { useEffect } from 'react';


const Pstep = () => {

  useEffect(() => {



var cursor = document.querySelector(".follow-img");

document.addEventListener("mousemove", (e) => {
  var x = e.clientX;
  var y = e.clientY;

  cursor.style.left = `${x}px`;
  cursor.style.top = `${y}px`;
});

    
    
  }, []);

    return (
        <>
        
           
           <section className="web-section2" >
          <div className='container'>
          <div className='row'>
           <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
           <div className='Personalized-two'>
     <h2>Simple 4 Step Process</h2>
  </div>
</div>
<div className='row' id='process-border'>
<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12" >
 <p className="strong">01</p>
  </div>
   
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" >
   <p className="strong">Discover and Define
   <a href="#">
<img src="https://github.com/coreDeiv/reveal-and-follow-image-on-text-hover/blob/master/cover-img.jpg?raw=true" alt="img-contain" class="follow-img"/>
</a>
  </p>
   </div>
   
   <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12" >
    
     <p>
     Our journey begins by deeply understanding your business objectives, target audience, and unique requirements.
     </p>
   </div>
   </div>
   <div className='row' id='process-border'>
   <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12" >
 <p className="strong">02</p>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" >
     <p className="strong">Design and Develop</p>
   </div>
   <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12" >
    <p>
    Armed with insights and a well-defined strategy, our passionate team of designers and developers embark on transforming ideas into captivating digital realities.
     </p>
   </div>
   </div>
   <div className='row' id='process-border'>
   <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12" >
 <p className="strong">03</p>
  </div>
   
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" >
     
     <p className="strong">Promote and Optimize</p>
   </div>
   
   <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-4 col-12" >
    <p>
    With your digital masterpiece in place, we shift our focus to propelling your online success.     </p>
   </div>
   </div>
   <div className='row' id='process-border'>
   <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12" >
 <p className="strong">04</p>
  </div>
   
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12" >
     
     <p className="strong">Evolve and Sustain</p>
   </div>
   
   <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12" >
    
     <p>
     We are committed to staying by your side, continuously evolving and refining your web solutions to meet evolving market dynamics and customer expectations.     </p>
   </div>
   </div>
          </div>
         </div>
        </section>
        
  

         </>
        )
        }

export default Pstep



