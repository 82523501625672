import React, { useEffect } from 'react';
import MyNavbar from '../global-components/mynavbar';
import MyFooter from '../global-components/my-footer';
import $ from 'jquery';
import misson from '../../images/web/misson.gif';
import visson from '../../images/web/vision.webp';
import AboutF1 from '../section-components/about-F1';
import s11 from '../../images/web/s11.webp';
import s22 from '../../images/web/s22.webp';
import s33 from '../../images/web/s33.webp';
import s44 from '../../images/web/s44.webp';

const AboutUs = () => {

    useEffect(() => {
var titleMain  = $("#animatedHeading");
var titleSubs  = titleMain.find("slick-active");

if (titleMain.length) {

  titleMain.slick({
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 4,
    centerPadding: "10px",
    draggable: false,
    infinite: true,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    vertical: true,
    speed: 1000,
    autoplaySpeed: 2000,
    useTransform: true,
    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    adaptiveHeight: true,
  });

  // On init
  $(".slick-dupe").each(function(index, el) {
    $("#animatedHeading").slick('slickAdd', "<div>" + el.innerHTML + "</div>");
  });

  // Manually refresh positioning of slick
  titleMain.slick('slickPlay');
};
            
          }, []);
 
    return (
<>
<MyNavbar />
 <section className='about-top'>
<div className='container'>
    <div className='row'>
    <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12'>
        <h1>
About Perfect Web Services</h1>
<p>
Perfect Web Services Company has an experienced and fanatical team of designers and developers. We work ingeniously and ardently to face and win every challenge we come across.
</p>
<p>
We don’t only take your order and serve you the required services. Instead, we take time to study your business goals and objectives to build a strategic plan to design your website. With our unique design features, we create website that expresses your business values and increase the engagement of the audience that visits your site.
</p>
    </div>
    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12'>
    <div className="hero__title" id="animatedHeading">
      
  <div className="slick-dupe">
    <span className="hero__title-misc  |  animate">
    <img src={s11} alt='' className='sicon'/>
 Creative Design 
</span>
    
  </div>
 
  <div className="slick-dupe">
    <span className="hero__title-misc  |  animate"> <img src={s22} alt='' className='sicon'/>
    Web Development</span>

  </div>
  <div className="slick-dupe">
    <span className="hero__title-misc  |  animate">
    <img src={s44} alt='' className='sicon'/>
      E-commerce Solution</span>
  </div>
  <div className="slick-dupe">
    <span className="hero__title-misc  |  animate">
    <img src={s33} alt='' className='sicon'/>Digital Marketing</span>
  </div>
</div>

    </div>
    </div>
</div>
</section>
<section className="about-serivces">
  <div className="container">
    <div className="row">
      <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
        <div className="av-cta">
          <h2>
          We connect with our clients our communities, and our company. <span className="slimtext">It makes us different. It makes us better.</span>
          </h2>
         
        </div>
      </div>
    </div>
  </div>
</section>
<section className='about-two'>
    <div className='container'>
        <div className='row' id='center'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='ab2'>
        <h2>Our Mission</h2>
        <p>
        Our mission is to establish, prepare and provide user friendly, lucrative, flexible and comprehensive solutions to the present and future clients and to maintain a healthy relationship with our clients at the same time.Also to provide the latest technologies services that Improves every client’s Business.Our prime mission is to meet customer requirements as well as our commitments by offering best Web Design and Development services & solutions in the ever-changing world of technology.
</p>

        </div>
       <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='ab1'>
        <img src= {misson} alt='misson'className='ourmisson'/>
    
</div>


        </div>
    </div>
</section>
<section className='about-three'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <img src= {visson} alt='misson'className='visson'/>
    
</div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='ab2'>
        <h2>Our Vision</h2>
        <p>
        Our mission is to establish, prepare and provide user friendly, lucrative, flexible and comprehensive solutions to the present and future clients and to maintain a healthy relationship with our clients at the same time.Also to provide the latest technologies services that Improves every client’s Business.Our prime mission is to meet customer requirements as well as our commitments by offering best Web Design and Development services & solutions in the ever-changing world of technology.
   </p>
   </div>
    </div>
    </div>
</section>

<AboutF1/>

<MyFooter/>



</>
)
}

export default AboutUs

