import React from 'react';


const TabForm = () => {
    return (
<>
<section className='tab-from'>
    <div className='container'>
        <div className='row'>
        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12">
            <h2>Let's talk business.<br></br>
            <span class="colorblue">But you first.</span>
            </h2>
            <p class="challenges">Describe your idea. The more details the better. Here’s what will happen next: we reply within 3 business hours; together we discuss your project on a quick call; you get a nicely presented quote from Fulcrum.</p>
        
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12">
        <div className="tab-content">
			          <div className="tab-pane fade" id="liton_tab_3_1">
			            <div className="ltn__apartments-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-12">
                            <h4 className="title-22">Contacts information</h4>
  <form action="contact.php" method="post" encType="contact/form-data">
    <div className="row">
      <div className="col-md-6">
        <div className="input-item input-item-name ">
          <input type="text" name="name" placeholder="First Name*" required="" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-item input-item-name ">
          <input
            type="text"
            name="lastname"
            placeholder=" Last Name"
            required=""
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-item input-item-email ">
          <input type="email" name="email" placeholder=" Email  Address*" required="" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-item input-item-name ">
          <input
            type="text"
            name="companyname"
            placeholder="Website URL*"
            required=""
          />
        </div>
      </div>
      </div>
      <div className="input-item input-item-textarea">
      <textarea name="message" placeholder="Tell us about your project" defaultValue={""} />
    </div>
    <div className="btn-wrapper mt-0">
      <button
        className="btnc theme-btn-c btnc-effect-1 text-uppercase"
        type="submit"
      >
Get a quote      </button>
    </div>
    <p className="form-messege mb-0 mt-20" />
  </form>
			                 
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade active show" id="liton_tab_3_2">
			            <div className="ltn__product-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-12">
                            <p className="title-222">Services</p>
                            <form action="contact.php" method="post" encType="contact/form-data">
    <div className="row">
    <div className="col-md-12">

  <div className="cat action">
    <label>
      <input type="checkbox" defaultValue={1} />
      <span>Product development</span>
    </label>
  </div>
  <div className="cat comedy">
    <label>
      <input type="checkbox" defaultValue={1} />
      <span>UI/UX design</span>
    </label>
  </div>
  <div className="cat comedy">
    <label>
      <input type="checkbox" defaultValue={1} />
      <span>Frontend</span>
    </label>
  </div>
  <div className="cat comedy">
    <label>
      <input type="checkbox" defaultValue={1} />
      <span>Backend</span>
    </label>
  </div>
</div>
<div className="col-md-12">
<p className="title-22">Contacts information</p>
</div>
      <div className="col-md-6">
        <div className="input-item input-item-name ">
          <input type="text" name="name" placeholder="First Name*" required="" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-item input-item-name ">
          <input
            type="text"
            name="lastname"
            placeholder=" Last Name"
            required=""
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-item input-item-email ">
          <input type="email" name="email" placeholder=" Email  Address*" required="" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-item input-item-name ">
          <input
            type="text"
            name="companyname"
            placeholder="Website URL*"
            required=""
          />
        </div>
      </div>
      </div>
      <div className="input-item input-item-textarea">
      <textarea name="message" placeholder="Tell us about your project" defaultValue={""} />
    </div>
    <div className="btn-wrapper mt-0">
      <button
        className="btnc theme-btn-c btnc-effect-1 text-uppercase"
        type="submit"
      >
Get a quote      </button>
    </div>
    <p className="form-messege mb-0 mt-20" />
  </form>
			                 
	</div>
	</div>
	</div>
	</div>
	</div>
	</div>
  </div>
  </div>
</section>
   

 </>
)
}

export default TabForm

