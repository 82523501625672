import React from 'react';
import p1 from '../../images/web/p1.webp';
import pbg from '../../images/web/pbg.webp';
import p2 from '../../images/web/p2.webp';
import p3 from '../../images/web/p3.webp';
import p4 from '../../images/web/p4.webp';
import p5 from '../../images/web/p5.webp';
import p6 from '../../images/web/p6.webp';


const Webport1 = () => {
    
    return (
        <>
         <section className="port-section3" >
          <div className='container'>
          <div className='row'>
           <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
           <div className='Personalized-two'>
     <h2>Our Portfolio</h2>
  </div>
</div>
<div class="row row--15">
<div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio">
  <div className="rwt-card">
    <div className="inner">
      <div className="thumbnail">
        <figure className="card-image">
      
          <img src={p1} alt='' className='porject' />
       
        
        </figure>
    
      </div>
      <div className="content">
        <span className="subtitle b2 text-capitalize">Children</span>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio2">
  <div className="rwt-card">
    <div className="inner">
      <div className="thumbnail">
        <figure className="card-image">
      
          <img src={p2} alt='' className='porject' />
         
        
        </figure>
    
      </div>
      <div className="content">
        <span className="subtitle b2 text-capitalize">Save the Planet</span>
      </div>
    </div>
  </div>
</div>

</div>
<div class="row row--15"id='top-space'>
<div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio">
  <div className="rwt-card">
    <div className="inner">
      <div className="thumbnail">
        <figure className="card-image">
      
          <img src={p3} alt='' className='porject' />
        
        
        </figure>
    
      </div>
      <div className="content">
        <span className="subtitle b2 text-capitalize">Fundraising</span>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio2">
  <div className="rwt-card">
    <div className="inner">
      <div className="thumbnail">
        <figure className="card-image">
      
          <img src={p4} alt='' className='porject' />
        
        
        </figure>
    
      </div>
      <div className="content">
        <span className="subtitle b2 text-capitalize">Church</span>
      </div>
    </div>
  </div>
</div>

</div>
<div class="row row--15">
<div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio">
  <div className="rwt-card">
    <div className="inner">
      <div className="thumbnail">
        <figure className="card-image">
      
          <img src={p6} alt='' className='porject' />
      
        
        </figure>
    
      </div>
      <div className="content">
        <span className="subtitle b2 text-capitalize">Environment</span>
      </div>
    </div>
  </div>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio2">
  <div className="rwt-card">
    <div className="inner">
      <div className="thumbnail">
        <figure className="card-image">
      
          <img src={p5} alt='' className='porject' />
        
        
        </figure>
    
      </div>
      <div className="content">
        <span className="subtitle b2 text-capitalize">Sterling</span>
      </div>
    </div>
  </div>
</div>

</div>
</div>
 

  
         </div>
        </section>
         </>
        )
        }

export default Webport1



