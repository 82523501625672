import React from "react";
import PortfolioV2 from "./portfolio-v2";

  class Gallery extends React.Component {
   render() {
      
      return (
       <PortfolioV2/> 
       
      );
    }
  }
  export default Gallery  